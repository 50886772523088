<template>
  <div class="layout-content">
    <el-row style="margin-bottom: 20px;">
      <el-switch
        v-model="tagWithoutIcon"
        active-text="获取没有图标"
        inactive-text="所有"
        active-color="#ee4969"
        inactive-color="#33ce96"
      >
      </el-switch>
    </el-row>
    <el-row type="flex" justify="space-between">
      <el-table :data="tagList" border style="width: 100%">
        <el-table-column fixed prop="_id" label="ID" width="240"> </el-table-column>
        <el-table-column prop="value" label="标签" width="200">
          <template slot-scope="scope">
            <el-input v-model="scope.row.value"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="pinyin" label="拼音" width="200"> </el-table-column>
        <el-table-column prop="icon" label="icon" width="240">
          <template slot-scope="scope">
            <el-input v-model="scope.row.icon">
              <span slot="append">
                <i :class="scope.row.icon"></i>
              </span>
            </el-input>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="300">
          <template slot-scope="scope">
            <el-button @click="update(scope.row)" type="text" size="small">保存</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
  </div>
</template>

<script>
import moment from "moment";
// import axios from "axios";
import { store } from "../store";

const host = process.env.VUE_APP_HOST_URL;

export default {
  data() {
    return {
      tagWithoutIcon: true,
      currentTag: "",
      tagList: [],
    };
  },
  mounted() {
    // 切换主题
    store.setTheme("light");
    this.getTagWithoutIconList();
  },
  watch: {
    tagWithoutIcon() {
      this.getTagList();
    },
  },
  methods: {
    formatDate(date) {
      console.log(moment.locale());
      let m = moment(date);
      let now = moment();
      if (m.year() != now.year()) {
        return m.format("YYYY-MM-DD");
      } else if (m.month() != now.month()) {
        return m.format("MM月DD日");
      } else if (m.day() != now.day()) {
        return m.format("MM月DD日");
      }
      return moment(date).fromNow();
    },
    getTagList() {
      if (this.tagWithoutIcon) {
        this.getTagWithoutIconList();
      } else {
        this.getAllTag();
      }
    },
    getTagWithoutIconList() {
      this.axios.get(`${host}/note/tag/icon/no`).then((response) => {
        this.tagList = response.data.data;
        console.log(this.tagList);
        console.log("加载标签列表 success");
      });
    },
    getAllTag() {
      this.axios.get(`${host}/note/tag/all`).then((response) => {
        this.tagList = response.data.data;
        console.log(this.tagList);
        console.log("加载标签列表 success");
      });
    },
    // 更新标签值和 icon
    update(data) {
      console.log(data);
      let updateData = {
        id: data._id,
        icon: data.icon,
        value: data.value,
      };
      this.axios.post(`${host}/note/tag/update`, updateData).then((response) => {
        // this.tagList = response.data.data;
        console.log("更新标签 success", response);
        this.$notify({
          title: "更新标签成功",
          message: "",
        });
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
